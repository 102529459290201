.textjustify {
	text-align: justify;
}

.mt-10 {
	margin-top: 10px;
}

.mt-5 {
	margin-top: 5px;
}
.p-10 {
	padding: 10px;
}
